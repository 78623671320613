import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

export interface UserState {
  loading: boolean
  error: string | null
  isAuthenticated: boolean
  userId: string
  firstName: string
  lastName: string
  email: string
  firstOrder: boolean
}

const initialState: UserState = {
  loading: false,
  error: null,
  isAuthenticated: false,
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  firstOrder: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    registrationRequest: (state) => {
      state.loading = true
      state.error = null
    },
    registrationSuccess: (state) => {
      state.loading = false
      state.isAuthenticated = true
    },
    registrationFailure: (state, action: PayloadAction<string>) => {
      state.loading = false
      state.error = action.payload
    },
    logout: (state) => {
      state.isAuthenticated = false
      state.firstName = ''
      state.lastName = ''
      state.email = ''
      Cookies.remove('isAuthenticated')
      Cookies.remove('userIdPlates')
      Cookies.remove('userFirstNamePlates')
      Cookies.remove('userLastNamePlates')
      Cookies.remove('userEmailPlates')
      Cookies.remove('userFirstNamePlates')
      Cookies.remove('userLastNamePlates')
      Cookies.remove('userEmailPlates')
      Cookies.remove('firstOrderPlates')
      sessionStorage.clear()
      localStorage.clear()
    },
    setUserDetails: (
      state,
      action: PayloadAction<{
        userId: string
        firstName: string
        lastName: string
        email: string
        firstOrder: boolean
      }>
    ) => {
      const { userId, firstName, lastName, email, firstOrder } = action.payload
      state.userId = userId
      state.firstName = firstName
      state.lastName = lastName
      state.email = email
      state.firstOrder = firstOrder
      state.isAuthenticated = true
      Cookies.set('isAuthenticated', 'true', {
        expires: 7,
        secure: true,
        sameSite: 'strict',
      })
      Cookies.set('userIdPlates', userId, { expires: 7 })
      Cookies.set('userFirstNamePlates', firstName, { expires: 7 })
      Cookies.set('userLastNamePlates', lastName, { expires: 7 })
      Cookies.set('userEmailPlates', email, { expires: 7 })
      Cookies.set('firstOrderPlates', firstOrder.toString(), { expires: 7 })
    },
  },
})

export const {
  registrationRequest,
  registrationSuccess,
  registrationFailure,
  setUserDetails,
  logout,
} = userSlice.actions

export default userSlice.reducer
