// src/components/Loader.tsx
import React from "react";
import logo from "../assets/images/logo/Plates_Logo_RGB.svg"; // הנתיב לקובץ הלוגו
import "./Loader.css"; // קובץ CSS מותאם אישית

const Loader: React.FC = () => {
  return (
    <div className="loader">
      <img src={logo} alt="Loading..." />
    </div>
  );
};

export default Loader;
