import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import * as Sentry from '@sentry/react'
const theme = createTheme()

Sentry.init({
  dsn: 'https://ee730575e11a6208d2a109e13313bb1e@o4507751088062464.ingest.de.sentry.io/4507950622310480',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <MUIThemeProvider theme={theme}>
    <StyledThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </StyledThemeProvider>
  </MUIThemeProvider>
)

reportWebVitals()
