import { onLCP, onINP, onCLS } from 'web-vitals'

const reportWebVitals = (onPerfEntry?: (metric: any) => void) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    onLCP(onPerfEntry)
    onCLS(onPerfEntry)
    onINP(onPerfEntry)
    // הסר את getTTFB אם אינו נתמך
    // getTTFB(onPerfEntry);
  }
}

export default reportWebVitals
