// redux/couponSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CouponState {
  code: string;
  discount: number;
  priceAfterDiscount: number;
  isLoading: boolean;
  error: string | null;
  couponId: string | null;
}

const initialState: CouponState = {
  code: "",
  discount: 0,
  priceAfterDiscount: 0,
  isLoading: false,
  error: null,
  couponId: null,
};

const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    setDiscount: (state, action: PayloadAction<number>) => {
      state.discount = action.payload;
    },
    setPriceAfterDiscount: (state, action: PayloadAction<number>) => {
      state.priceAfterDiscount = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setCouponId: (state, action: PayloadAction<string | null>) => {
      state.couponId = action.payload;
    },
  },
});

export const {
  setCode,
  setDiscount,
  setPriceAfterDiscount,
  setLoading,
  setError,
  setCouponId,
} = couponSlice.actions;

export default couponSlice.reducer;
