import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'

interface PlanState {
  selectedPeople: any // Changed from number | null to any
  selectedMeals: any // Changed from number | null to any
  selectedTitles: string[]
  price: number
  priceAfterCoupon: number
}

type PlanStateKey = keyof PlanState

const initialState: PlanState = {
  selectedPeople: null,
  selectedMeals: null,
  selectedTitles: [],
  price: 0,
  priceAfterCoupon: 0,
}

function parseNumberOrNull(value: string): number | null {
  const parsed = Number(value)
  return isNaN(parsed) ? null : parsed
}

function parseNumber(value: string): number {
  return Number(value)
}

function parseStringArray(value: string): string[] {
  try {
    const parsed = JSON.parse(value)
    return Array.isArray(parsed) ? parsed : []
  } catch {
    return []
  }
}

function parseValue<K extends PlanStateKey>(
  key: K,
  value: string
): PlanState[K] {
  switch (key) {
    case 'selectedPeople':
    case 'selectedMeals':
      return parseNumberOrNull(value) as PlanState[K]
    case 'price':
    case 'priceAfterCoupon':
      return parseNumber(value) as PlanState[K]
    case 'selectedTitles':
      return parseStringArray(value) as PlanState[K]
    default:
      throw new Error(`Unexpected key: ${key}`)
  }
}

export const encryptAndSaveToCookie = createAsyncThunk(
  'plan/encryptAndSave',
  async (
    { key, value }: { key: PlanStateKey; value: string },
    { rejectWithValue }
  ) => {
    try {
      console.log(`Encrypting data for key: ${key}, value: ${value}`)
      const response = await axios.post(
        'https://backfood-aex3.onrender.com/api/encrypt',
        {
          data: value,
        }
      )
      console.log('Encryption response:', response.data)
      if (response.data.encryptedData) {
        Cookies.set(key, response.data.encryptedData, { expires: 7 })
        console.log(`Cookie set for key: ${key}`)
        return { key, value }
      } else {
        throw new Error('No encrypted data in response')
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.response?.data)
        return rejectWithValue(
          error.response?.data?.error || 'Encryption failed'
        )
      } else if (error instanceof Error) {
        console.error('Error:', error.message)
        return rejectWithValue(error.message)
      } else {
        console.error('Unknown error:', error)
        return rejectWithValue('An unknown error occurred')
      }
    }
  }
)
export const decryptFromCookie = createAsyncThunk(
  'plan/decryptFromCookie',
  async (key: PlanStateKey, { rejectWithValue }) => {
    const encryptedData = Cookies.get(key)
    if (!encryptedData) return null

    try {
      const { data } = await axios.post(
        'https://backfood-aex3.onrender.com/api/decrypt',
        {
          encryptedData,
        }
      )
      return { key, value: data.decryptedData }
    } catch (error) {
      return rejectWithValue('Decryption failed')
    }
  }
)

export const loadStateFromCookies = createAsyncThunk(
  'plan/loadState',
  async (_, { dispatch }) => {
    const keys: PlanStateKey[] = [
      'selectedPeople',
      'selectedMeals',
      'selectedTitles',
      'price',
      'priceAfterCoupon',
    ]
    const results = await Promise.all(
      keys.map((key) => dispatch(decryptFromCookie(key)))
    )

    const loadedState: Partial<PlanState> = {}

    results.forEach((result) => {
      if (result.payload) {
        const { key, value } = result.payload as {
          key: PlanStateKey
          value: string
        }
        loadedState[key] = parseValue(key, value)
      }
    })

    return loadedState
  }
)

const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        encryptAndSaveToCookie.fulfilled,
        (
          state,
          action: PayloadAction<{ key: PlanStateKey; value: string }>
        ) => {
          const { key, value } = action.payload
          state[key] = parseValue(key, value)
        }
      )
      .addCase(
        loadStateFromCookies.fulfilled,
        (state, action: PayloadAction<Partial<PlanState>>) => {
          Object.assign(state, action.payload)
        }
      )
  },
})

export const setSelectedPeopleRedux = (value: number) =>
  encryptAndSaveToCookie({ key: 'selectedPeople', value: value.toString() })
export const setSelectedMealsRedux = (value: number) =>
  encryptAndSaveToCookie({ key: 'selectedMeals', value: value.toString() })
export const setSelectedTitlesRedux = (value: string[]) =>
  encryptAndSaveToCookie({
    key: 'selectedTitles',
    value: JSON.stringify(value),
  })
export const setPrice = (value: number) =>
  encryptAndSaveToCookie({ key: 'price', value: value.toString() })
export const setPriceAfterCoupon = (value: number) =>
  encryptAndSaveToCookie({ key: 'priceAfterCoupon', value: value.toString() })

export default planSlice.reducer
